import React from "react";
import "./App.css";
import Header from "./components/header/Header";
import Home from "./components/header/home/Home";
import About from "./components/header/about/About";
import Skills from "./components/header/skills/Skills";
import Services from "./components/services/Services";
import Qualification from "./components/qualification/Qualification";
import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";

const App = () => {
  return (
    <>
      <Header />
      <main className="main">
        <Home />
        <About />
        <Skills />
        <Services />
        <Qualification />
        <Testimonials />
        <Contact />
        <Footer />
      </main>
    </>
  );
};

export default App;
